import React, { useState, useEffect } from 'react';
import { updateUserInfo, getUserProfile } from '../services/Service';
import { useNavigate } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';

const UpdateInfo = () => {
    const [formData, setFormData] = useState({ firstName: '', lastName: '', socialLoginIds: '' });
    const [existingData, setExistingData] = useState({ firstName: '', lastName: '', socialLoginIds: '' });
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [messageColor, setMessageColor] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await getUserProfile();
                setExistingData(response);
                setFormData({
                    firstName: response.firstName || '',
                    lastName: response.lastName || '',
                    socialLoginIds: response.socialLoginIds || ''
                });
            } catch (err) {
                console.error('Error fetching profile:', err);
            }
        };

        fetchProfile();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const { firstName, lastName, socialLoginIds } = formData;
        if (!firstName && !lastName && !socialLoginIds) {
            setMessageColor('text-red-500');
            setMessage('At least one field must be filled out');
            setLoading(false);
            return;
        }

        try {
            const socialLoginIdsString = socialLoginIds.split(',').map(id => id.trim()).join(', ');
            const response = await updateUserInfo({ firstName, lastName, socialLoginIds: socialLoginIdsString });
            setMessageColor('text-green-500');
            setMessage(response);
            setExistingData({ firstName, lastName, socialLoginIds: socialLoginIdsString });
            setIsEditing(false);
            if (firstName) localStorage.setItem('firstName', firstName);
            if (lastName) localStorage.setItem('lastName', lastName);
            if (socialLoginIds) localStorage.setItem('socialLoginIds', socialLoginIdsString);
        } catch (err) {
            setMessageColor('text-red-500');
            setMessage(err.response || 'Update failed');
        }
        setLoading(false);
    };

    return (
        <div className="flex flex-col items-center p-8 bg-white shadow-lg rounded-lg max-w-lg mx-auto my-10">
            <h2 className="text-2xl font-bold text-gray-800 mb-5">Update Info</h2>
            {isEditing ? (
                <form onSubmit={handleSubmit} className="flex flex-col w-full">
                    <input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="p-3 mb-4 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                    />
                    <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="p-3 mb-4 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                    />
                    <input
                        type="text"
                        name="socialLoginIds"
                        placeholder="Social Login IDs (comma separated)"
                        value={formData.socialLoginIds}
                        onChange={handleChange}
                        className="p-3 mb-4 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                    />
                    <button
                        type="submit"
                        disabled={loading}
                        className={`p-3 text-white bg-blue-600 rounded hover:bg-blue-700 ${loading && 'opacity-50 cursor-not-allowed'}`}
                    >
                        {loading ? 'Updating...' : 'Update'}
                    </button>
                </form>
            ) : (
                <div className="w-full">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-medium text-gray-700">Existing Information</h3>
                        <button onClick={() => setIsEditing(true)} className="text-blue-600 hover:text-blue-800">
                            <FaEdit size={20} />
                        </button>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">First Name</label>
                        <input
                            type="text"
                            name="existingFirstName"
                            value={existingData.firstName}
                            disabled
                            className="p-3 border border-gray-300 rounded bg-gray-100 focus:outline-none w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Last Name</label>
                        <input
                            type="text"
                            name="existingLastName"
                            value={existingData.lastName}
                            disabled
                            className="p-3 border border-gray-300 rounded bg-gray-100 focus:outline-none w-full"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Social Login IDs</label>
                        <input
                            type="text"
                            name="existingSocialLoginIds"
                            value={existingData.socialLoginIds}
                            disabled
                            className="p-3 border border-gray-300 rounded bg-gray-100 focus:outline-none w-full"
                        />
                    </div>
                </div>
            )}
            {message && <p className={`mt-4 font-medium ${messageColor}`}>{message}</p>}
        </div>
    );
};

export default UpdateInfo;
