import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import SignUp from './components/auth/SignUp';
import Signin from './components/auth/Signin';
import ProtectedRoute from './components/auth/ProtectedRoute';
import AdminWelcome from './components/admin/AdminWelcome';
import UserWelcome from './components/user/UserWelcome';
import CreateRole from './components/admin/CreateRole';
import CreatePermission from './components/admin/CreatePermission';
import AssignRole from './components/admin/AssignRole';
import AssignPermission from './components/admin/AssignPermission';
import Navbar from './components/Sidebar/Sidebar';
import UpdateInfo from './components/user/UpdateInfo'; 
import ChangePassword from './components/user/ChangePassword';

const NavbarContainer = () => {
    const location = useLocation();
    const shouldRenderNavbar = !['/', '/signup'].includes(location.pathname);

    return shouldRenderNavbar ? <Navbar /> : null;
};

const App = () => {
    const [isSignUp, setIsSignUp] = useState(false);

    const toggleForm = () => {
        setIsSignUp(!isSignUp);
    };

    const basePath = process.env.REACT_APP_BASE_PATH || '/';

    return (
        <Router basename={basePath}>
            <div className="App flex flex-col h-screen">
                <NavbarContainer />
                <div className="flex justify-center items-center flex-grow bg-gradient-to-b from-blue-200 to-gray-200">
                    <Routes>
                        <Route path="/" element={isSignUp ? <SignUp toggleForm={toggleForm} /> : <Signin toggleForm={toggleForm} />} />
                        <Route path="/UserWelcome" element={<ProtectedRoute roles={['USER']}><UserWelcome /></ProtectedRoute>} />
                        <Route path="/AdminWelcome" element={<ProtectedRoute roles={['ADMIN']}><AdminWelcome /></ProtectedRoute>} />
                        <Route path="/create-role" element={<ProtectedRoute roles={['ADMIN']}><CreateRole /></ProtectedRoute>} />
                        <Route path="/create-permission" element={<ProtectedRoute roles={['ADMIN']}><CreatePermission /></ProtectedRoute>} />
                        <Route path="/assign-role" element={<ProtectedRoute roles={['ADMIN']}><AssignRole /></ProtectedRoute>} />
                        <Route path="/assign-permission" element={<ProtectedRoute roles={['ADMIN']}><AssignPermission /></ProtectedRoute>} />
                        <Route path="/update-info" element={<ProtectedRoute roles={['USER']}><UpdateInfo /></ProtectedRoute>} /> {/* Add new route */}
                        <Route path="/change-password" element={<ProtectedRoute roles={['USER', 'ADMIN']}><ChangePassword /></ProtectedRoute>} />
                        <Route path="*" element={<div>404 Not Found</div>} />
                    </Routes>
                </div>
            </div>
        </Router>
    );
};

export default App;
