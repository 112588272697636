import axios from 'axios';
import { SECURITY_BACKEND_URL } from '../auth/constants';
import { REDIRECT_URI } from '../auth/constants';

const getToken = () => {
    return localStorage.getItem('token');
};

// Helper function to get headers
const getAuthHeaders = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`
    }
});

// Authentication Endpoints (3)
export const signin = async (email, password) => {
    try {
        const response = await axios.post(`${SECURITY_BACKEND_URL}/auth/signin`, { email, password });
        return response.data;
    } catch (error) {
        console.error("Signin error:", error);
        throw error;
    }
};

export const signup = async (formData) => {
    try {
        const response = await axios.post(`${SECURITY_BACKEND_URL}/auth/signup`, formData);
        return response.data;
    } catch (error) {
        console.error("Signup error:", error);
        throw error;
    }
};

export const signout = async () => {
    try {
        const response = await axios.get(`${SECURITY_BACKEND_URL}/auth/signout`, getAuthHeaders());
        return response.data;
    } catch (error) {
        console.error("Signout error:", error);
        throw error;
    }
};

// Forgot Password Endpoint (1)
export const forgotPassword = async (email) => {
    try {
        const response = await axios.get(`${SECURITY_BACKEND_URL}/auth/forgot-password`, {
            params: { email }
        });
        return response.data;
    } catch (error) {
        console.error("Forgot password error:", error);
        throw error;
    }
};

//Change-Password (1)
export const changePassword = async (currentPassword, newPassword, confirmNewPassword) => {
    try {
        const response = await axios.post(
            `${SECURITY_BACKEND_URL}/auth/change-password`,
            { currentPassword, newPassword, confirmNewPassword },
            getAuthHeaders()
        );
        return response.data;
    } catch (error) {
        console.error("Change password error:", error);
        throw error;
    }
};

export const social = async (socialId) => {
    try {
        const response = await axios.post(`${SECURITY_BACKEND_URL}/auth/social/mapping`, { socialId });
        return response.data;
    } catch (error) {
        console.error("Mapping error:", error);
        throw error;
    }
};

// Google Signin Endpoints
export const googleAccessToken = async (code) => {
    try {
        const response = await axios.post(`${SECURITY_BACKEND_URL}/auth/google/accesstoken`, {
            code,
            redirect_uri: REDIRECT_URI
        });
        return response.data;
    } catch (err) {
        console.error('Error exchanging authorization code for access token:', err.response || err.message || err);
        throw new Error('Error exchanging authorization code for access token');
    }
};

export const googleUserData = async (accessToken) => {
    try {
        const response = await axios.get(`${SECURITY_BACKEND_URL}/auth/google/id`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data;
    } catch (err) {
        console.error('Error fetching Google user data:', err.response || err.message || err);
        throw new Error('Error fetching Google user data');
    }
};

// GitHub Signin Endpoints
export const githubAccessToken = async (code) => {
    try {
        const response = await axios.post(`${SECURITY_BACKEND_URL}/auth/github/accesstoken`, { 
            code, 
            redirect_uri:REDIRECT_URI
        });
        return response.data;
    } catch (err) {
        console.error('Error exchanging authorization code for access token:', err.response || err.message || err);
        throw new Error('Error exchanging authorization code for access token');
    }
};

export const githubUserData = async (accessToken) => {
    try {
        const response = await axios.get(`${SECURITY_BACKEND_URL}/auth/github/id`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data;
    } catch (err) {
        console.error('Error fetching GitHub user data:', err.response || err.message || err);
        throw new Error('Error fetching GitHub user data');
    }
};


// Role and Permission Endpoints (3)
export const fetchAccessPermissions = async (email) => {
    try {
        const response = await axios.post(`${SECURITY_BACKEND_URL}/admin/accesspermission`, { email }, getAuthHeaders());
        return response.data;
    } catch (error) {
        console.error("Fetch access permissions error:", error);
        throw error;
    }
};

export const fetchRoles = async () => {
    try {
        const response = await axios.get(`${SECURITY_BACKEND_URL}/admin/role`, getAuthHeaders());
        return response.data;
    } catch (error) {
        console.error("Fetch roles error:", error);
        throw error;
    }
};

export const fetchPermissions = async () => {
    try {
        const response = await axios.get(`${SECURITY_BACKEND_URL}/admin/permission`, getAuthHeaders());
        return response.data;
    } catch (error) {
        console.error("Fetch permissions error:", error);
        throw error;
    }
};


//assign role and permissions (2)
export const assignRole = async (email, roles) => {
    try {
        const response = await axios.post(`${SECURITY_BACKEND_URL}/admin/assignrole`, { email, roles }, getAuthHeaders());
        return response.data;
    } catch (error) {
        console.error("Assign role error:", error);
        throw error;
    }
};

export const assignPermission = async (email, permissions) => {
    try {
        const response = await axios.post(`${SECURITY_BACKEND_URL}/admin/assignpermission`, { email, permissions }, getAuthHeaders());
        return response.data;
    } catch (error) {
        console.error("Assign permission error:", error);
        throw error;
    }
};


//creating roles and permissions (2)
export const createRole = async (roles) => {
    try {
        const response = await axios.post(`${SECURITY_BACKEND_URL}/admin/createrole`, { roles }, getAuthHeaders());
        return response.data;
    } catch (error) {
        console.error("Create role error:", error);
        throw error;
    }
};

export const createPermission = async (permissions) => {
    try {
        const response = await axios.post(`${SECURITY_BACKEND_URL}/admin/createpermission`, { permissions }, getAuthHeaders());
        return response.data;
    } catch (error) {
        console.error("Create permission error:", error);
        throw error;
    }
};

// User Info Endpoints (2)
export const getUserProfile = async () => {
    try {
        const response = await axios.get(`${SECURITY_BACKEND_URL}/auth/myprofile`, getAuthHeaders());
        return response.data;
    } catch (error) {
        console.error("Get user profile error:", error);
        throw error;
    }
};

export const updateUserInfo = async (formData) => {
    try {
        const response = await axios.post(`${SECURITY_BACKEND_URL}/auth/updateprofile`, formData, getAuthHeaders());
        return response.data;
    } catch (error) {
        console.error("Update user info error:", error);
        throw error;
    }
};
