import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { fetchAccessPermissions, assignRole, fetchRoles } from '../services/Service';

const AssignRole = ({ sidebarOpen }) => {
    const [email, setEmail] = useState('');
    const [roles, setRoles] = useState([]);
    const [availableRoles, setAvailableRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingRoles, setLoadingRoles] = useState(false);
    const [message, setMessage] = useState('');
    const [messageColor, setMessageColor] = useState('');

    useEffect(() => {
        if (email) {
            fetchExistingRoles();
        } else {
            setRoles([]);
        }
    }, [email, sidebarOpen]);

    useEffect(() => {
        fetchAvailableRoles();
    }, [sidebarOpen]);

    const fetchExistingRoles = async () => {
        setLoading(true);
        try {
            const response = await fetchAccessPermissions(email);
            const existingRoles = response.roles.map(role => ({ value: role, label: role }));
            setRoles(existingRoles);
        } catch (err) {
            console.error('Error fetching existing roles:', err);
            setRoles([]);
        }
        setLoading(false);
    };

    const fetchAvailableRoles = async () => {
        setLoadingRoles(true);
        try {
            const response = await fetchRoles();
            const roles = response.map(role => ({ value: role, label: role }));
            setAvailableRoles(roles);
        } catch (err) {
            console.error('Error fetching available roles:', err);
            setAvailableRoles([]);
        }
        setLoadingRoles(false);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleRolesChange = (selectedOptions) => {
        setRoles(selectedOptions);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const combinedRoles = roles.map(role => role.value);
        try {
            const response = await assignRole(email, combinedRoles);
            setMessage(response);
            setMessageColor('text-green-500');
            } catch (err) {
            console.log("ERROR",err);
            setMessage(err);
            setMessageColor('text-red-500');
        }
    };

    const allRolesOptions = availableRoles.map(role => ({ value: role.value, label: role.label }));

    return (
        <div className={`flex flex-col items-center px-10 pt-10 pb-20 bg-white shadow-md rounded-lg w-full max-w-md mx-auto my-5 ${sidebarOpen ? 'filter blur-lg brightness-50' : ''}`}>
            <h2 className="text-2xl font-bold text-gray-800 mb-5">Assign Role</h2>
            <form onSubmit={handleSubmit} className="w-full">
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>
                    <input
                        id="email"
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={handleEmailChange}
                        required
                        className="p-2 border rounded focus:outline-none focus:border-blue-500 w-full"
                    />
                </div>
                <div className="mb-6">
                    {loadingRoles ? (
                        <FontAwesomeIcon icon={faSpinner} className="text-gray-600 animate-spin" />
                    ) : (
                        <Select
                            isMulti
                            value={roles}
                            options={allRolesOptions}
                            onChange={handleRolesChange}
                            placeholder="Select roles"
                            className="mb-6"
                            closeMenuOnSelect={false}
                        />
                    )}
                </div>
                <button
                    type="submit"
                    className="px-4 py-2 text-white text-center bg-[#00AAFF] rounded hover:bg-blue-600"
                >
                    Assign Roles
                </button>
            </form>
            {message && <p className={`mt-3 font-bold ${messageColor}`}>{message}</p>}
        </div>
    );
};

export default AssignRole;
