import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from 'react-icons/fc';
import { googleAccessToken, googleUserData, social } from '../services/Service';

function GoogleSignin() {
  const navigate = useNavigate();
  const [message, setMessage] = useState({ text: '', color: '' });

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      const authorizationCode = response.code;

      try {
        const tokenResponse = await googleAccessToken(authorizationCode);
        const accessToken = tokenResponse.access_token;

        const userResponse = await googleUserData(accessToken);

        const user = userResponse;
        const email = user.email;

        const socialResponse = await social(email);

        localStorage.setItem('firstName', socialResponse.firstName);
        localStorage.setItem('token', socialResponse.token);
        localStorage.setItem('roles', JSON.stringify(socialResponse.roles));
        localStorage.setItem('permissions', JSON.stringify(socialResponse.permissions));

        navigate('/UserWelcome');
      } catch (err) {
        const errorResponse = err.response?.data || 'Google Login failed!';
        setMessage({ text: JSON.stringify(errorResponse), color: 'text-red-500 font-semibold' });
        console.error('Error:', err);
      }
    },
    onError: (error) => {
      setMessage({ text: 'Google Login failed!', color: 'text-red-500 font-semibold' });
      console.error('Error:', error);
    },
    flow: 'auth-code',
  });

  return (
    <>
      {message.text && <p className={`mt-3 ${message.color}`}>{message.text}</p>}
      <div className="flex items-center justify-center w-full space-y-4">
      <button
        onClick={login}
        className="bg-[#00AAFF] hover:bg-blue-500 text-white font-light py-3 px-4 mt-4 w-full rounded flex items-center justify-center space-x-3 shadow-md"
      >
        <FcGoogle size={24} className="bg-white text-2xl font-semibold rounded-full p-1" />
        <span>Sign in with Google</span>
      </button>
      </div>
    </>
  );
}

export default GoogleSignin;
