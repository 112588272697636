import React, { useState, useEffect } from 'react';
import { createPermission, fetchPermissions } from '../services/Service';

const CreatePermission = () => {
    const [newPermissions, setNewPermissions] = useState('');
    const [message, setMessage] = useState('');
    const [messageColor, setMessageColor] = useState('');
    const [existingPermissions, setExistingPermissions] = useState([]);

    useEffect(() => {
        const getPermissions = async () => {
            try {
                const permissions = await fetchPermissions();
                setExistingPermissions(permissions);
            } catch (err) {
                console.error('Error fetching permissions:', err);
            }
        };

        getPermissions();
    }, []);

    const handleNewPermissionsChange = (e) => {
        const upperCasePermissions = e.target.value.toUpperCase();
        setNewPermissions(upperCasePermissions);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const permissionsArray = newPermissions.split(',').map(permission => permission.trim()).filter(permission => permission !== '');

        try {
            const response = await createPermission(permissionsArray);
            console.log('Response:', response);
            setMessage(response);
            if (response === "Permissions creation was successful") {
                setMessageColor('text-green-500');
                setNewPermissions(''); // Clear the input field
                setExistingPermissions([...existingPermissions, ...permissionsArray]);
            } else {
                setMessageColor('text-red-500');
            }
        } catch (err) {
            console.error('Error:', err);
            if (err.response && err.response.data) {
                setMessage(err.response.data);
            } else {
                setMessage('Permission creation failed!');
            }
            setMessageColor('text-red-500');
        }
    };

    return (
        <div className="flex flex-col items-center px-10 pt-10 pb-20 bg-white shadow-md rounded-lg w-full max-w-md mx-auto my-5">
            <h2 className="text-2xl font-bold text-gray-800 mb-5">Create Permission</h2>
            <form onSubmit={handleSubmit} className="w-full">
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="permissions">Existing Permissions</label>
                    <div className="border border-gray-300 rounded-md p-2 h-14 overflow-y-scroll">
                        {existingPermissions.map((permission, index) => (
                            <p key={index} className="text-gray-700">{permission}</p>
                        ))}
                    </div>
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="new-permissions">Enter Permissions</label>
                    <input
                        type="text"
                        id="new-permissions"
                        value={newPermissions}
                        onChange={handleNewPermissionsChange}
                        placeholder="Enter new permissions separated by commas"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <button
                    type="submit"
                    className="px-4 py-2 text-white text-center bg-[#00AAFF] rounded hover:bg-blue-600"
                >
                    Create Permissions
                </button>
            </form>
            {message && <p className={`mt-3 font-bold ${messageColor}`}>{message}</p>}
        </div>
    );
};

export default CreatePermission;