import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import { GOOGLE_CLIENTID } from './components/auth/constants';
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <GoogleOAuthProvider clientId={GOOGLE_CLIENTID}>
    <App />
  </GoogleOAuthProvider>
);

reportWebVitals();
