import React, { useState } from 'react';
import { FiLock } from 'react-icons/fi';
import { changePassword } from '../services/Service';

function ChangePassword() {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const handleChangePassword = async () => {
        try {
            
            const response = await changePassword(currentPassword, newPassword, confirmNewPassword);
            setSuccessMessage(response); 
            console.log("RESPONSE",response);
            setCurrentPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
            setError(null);
        
        } catch (error) {
            console.error('Error changing password:', error);
            if (error.response && error.response.data) {
                setError(error.response.data);
                console.log("error:",error.response.data )
            } else {
                setError('Failed to change password. Please try again.');
            }
        }
    };

    return (
        <div className="flex flex-col items-center p-8 bg-white shadow-lg rounded-lg w-80 mx-auto my-5">
            <h2 className="text-2xl font-bold text-gray-800 mb-5">Change Password</h2>
            <div className="flex items-center mb-4">
                <FiLock className="inline-block mr-2" />
                <input
                    type="password"
                    placeholder="Current Password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    className="p-3 mb-3 border rounded focus:outline-none focus:border-blue-500 w-full"
                />
            </div>
            <div className="flex items-center mb-4">
                <FiLock className="inline-block mr-2" />
                <input
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="p-3 mb-3 border rounded focus:outline-none focus:border-blue-500 w-full"
                />
            </div>
            <div className="flex items-center mb-4">
                <FiLock className="inline-block mr-2" />
                <input
                    type="password"
                    placeholder="Confirm New Password"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    className="p-3 mb-3 border rounded focus:outline-none focus:border-blue-500 w-full"
                />
            </div>
            {error && <p className="text-red-500 mb-2">{error}</p>}
            {successMessage && <p className="text-green-500 mb-2">{successMessage}</p>}



            <button onClick={handleChangePassword} className="p-3 text-white bg-blue-600 rounded hover:bg-blue-700 focus:outline-none w-full">
                Change Password
            </button>
        </div>
    );
}

export default ChangePassword;
