import React, { useState, useEffect } from 'react';
import { createRole, fetchRoles } from '../services/Service';

const CreateRole = () => {
    const [newRoles, setNewRoles] = useState('');
    const [existingRoles, setExistingRoles] = useState([]);
    const [message, setMessage] = useState('');
    const [messageColor, setMessageColor] = useState('');

    useEffect(() => {
        const getRoles = async () => {
            try {
                const roles = await fetchRoles();
                setExistingRoles(roles);
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };
        getRoles();
    }, []);

    const handleNewRolesChange = (e) => {
        const upperCaseRoles = e.target.value.toUpperCase();
        setNewRoles(upperCaseRoles);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const rolesArray = newRoles.split(',').map(role => role.trim()).filter(role => role !== '');
        const allRoles = rolesArray;

        try {
            const response = await createRole(allRoles);
            console.log('Response:', response); 
            setMessage(response);
            if (response === "Roles creation was successful") {
                setMessageColor('text-green-500');
                setNewRoles('');
                const updatedRoles = await fetchRoles();
                setExistingRoles(updatedRoles);
            } else {
                setMessageColor('text-red-500');
            }
        } catch (err) {
            console.error('Error:', err); 
            if (err.response && err.response.data) {
                setMessage(err.response.data);
            } else {
                setMessage('Role creation failed!');
            }
            setMessageColor('text-red-500');
        }
    };

    return (
        <div className="flex flex-col items-center px-10 pt-10 pb-20 bg-white shadow-md rounded-lg w-full max-w-md mx-auto my-5">
            <h2 className="text-2xl font-bold text-gray-800 mb-5">Create Role</h2>
            <form onSubmit={handleSubmit} className="w-full">
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="existing-roles">Existing Roles</label>
                    <div className="border rounded p-2 h-14 overflow-y-scroll">
                        {existingRoles.map((role, index) => (
                            <p key={index} className="text-gray-700">{role}</p>
                        ))}
                    </div>
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="new-roles">Enter Roles</label>
                    <input
                        type="text"
                        id="new-roles"
                        value={newRoles}
                        onChange={handleNewRolesChange}
                        placeholder="Enter new roles separated by commas"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <button
                    type="submit"
                    className="px-4 py-2 text-white text-center bg-[#00AAFF] rounded hover:bg-blue-600"
                >
                    Create Roles
                </button>
            </form>
            {message && <p className={`mt-3 font-bold ${messageColor}`}>{message}</p>}
        </div>
    );
};

export default CreateRole;