import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signin, signup, forgotPassword } from '../services/Service';
import GoogleSignin from './GoogleSignin';
import GithubSignin from './GithubSignin';
import { FaSpinner } from 'react-icons/fa';

const Signin = ({ toggleForm }) => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [signupVisible, setSignupVisible] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await signin(formData.email, formData.password);
            localStorage.setItem('firstName', response.firstName);
            localStorage.setItem('token', response.token);
            localStorage.setItem('roles', JSON.stringify(response.roles));
            localStorage.setItem('permissions', JSON.stringify(response.permissions));

            const roles = JSON.parse(localStorage.getItem('roles'));
            navigate(roles.includes('ADMIN') ? '/AdminWelcome' : '/UserWelcome');
            setMessage({ text: 'Login successful', color: 'text-green-500' });
        } catch (err) {
            setMessage({ text: err.response?.data?.message || 'Login failed', color: 'text-red-500' });
        }
        setLoading(false);
    };

    const handleForgotPassword = async () => {
        if (!formData.email) {
            setMessage({ text: 'Please enter your email', color: 'text-red-500' });
            return;
        }

        setLoading(true);
        try {
            const response = await forgotPassword(formData.email);
            setMessage({ text: response, color: 'text-green-500' });
        } catch (err) {
            setMessage({ text: err.response?.data || 'Reset password failed', color: 'text-red-500' });
        }
        setLoading(false);
    };

    const handleSignupSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await signup(formData.email, formData.password);
            setMessage({ text: 'Signup successful', color: 'text-green-500' });
            setSignupVisible(false);
        } catch (err) {
            setMessage({ text: err.response?.data?.message || 'Signup failed', color: 'text-red-500' });
        }
        setLoading(false);
    };

    return (
        <div className="flex flex-col items-center p-10 bg-white shadow-md rounded-xl w-96 mx-auto my-10 space-y-4">
            {signupVisible ? (
                <>
                    <h2 className="text-3xl font-bold text-gray-800 mb-6">Create Account</h2>
                    <form onSubmit={handleSignupSubmit} className="flex flex-col w-full space-y-3">
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                            className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                        />
                        <button
                            type="submit"
                            disabled={loading}
                            className={`p-4 text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition duration-200 ${loading && 'opacity-50 cursor-not-allowed'}`}
                        >
                            {loading ? (
                                <span className="flex justify-center items-center space-x-2">
                                    <FaSpinner className="animate-spin" /> <span>Signing Up...</span>
                                </span>
                            ) : (
                                'Sign Up'
                            )}
                        </button>
                    </form>
                    {message.text && <p className={`mt-3 ${message.color}`}>{message.text}</p>}
                    <p className="mt-3 text-gray-600">
                        <span onClick={() => setSignupVisible(false)} className="text-blue-500 font-semibold hover:text-blue-700 cursor-pointer">
                            Back to Sign In
                        </span>
                    </p>
                </>
            ) : (
                <>
                    <h2 className="text-3xl font-bold text-gray-800 mb-6">Sign In</h2>
                    <form onSubmit={handleSubmit} className="flex flex-col w-full space-y-3">
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                            className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                        />
                        <button
                            type="submit"
                            disabled={loading}
                            className={`p-4 text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition duration-200 ${loading && 'opacity-50 cursor-not-allowed'}`}
                        >
                            {loading ? (
                                <span className="flex justify-center items-center space-x-2">
                                    <FaSpinner className="animate-spin" /> <span>Signing In...</span>
                                </span>
                            ) : (
                                'Sign In'
                            )}
                        </button>
                    </form>
                    {message.text && <p className={`mt-3 ${message.color}`}>{message.text}</p>}
                    <GoogleSignin />
                    <GithubSignin />
                    <p className="mt-3 text-gray-600">
                        Don't have an account?{' '}
                        <button onClick={toggleForm} className="text-blue-500 font-bold underline hover:text-blue-700">
                            Sign Up
                        </button>
                    </p>
                    <p className="mt-3 text-gray-600">
                        <button onClick={handleForgotPassword} className="text-blue-500 font-bold underline hover:text-blue-700">
                            Forgot Password
                        </button>
                    </p>
                </>
            )}
        </div>
    );
};

export default Signin;