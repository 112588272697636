import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signout } from '../services/Service';

const Signout = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSignout = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            setLoading(true);
            try {
                await signout();
                localStorage.clear();
                navigate('/');
            } catch (err) {
                console.error('Signout error:', err);
                alert('Signout failed. Please try again.');
            }
            setLoading(false);
        }
    };

    return (
        <button
            onClick={handleSignout}
            disabled={loading}
            className={`p-3 mr-10 text-white bg-red-500 rounded hover:bg-red-600 ${loading && 'opacity-50 cursor-not-allowed'}`}
        >
            {loading ? 'Signing Out...' : 'Sign Out'}
        </button>
    );
};

export default Signout;
