import React, { useState } from 'react';
import { FaGithub } from 'react-icons/fa';
import { GITHUB_CLIENTID, REDIRECT_URI } from '../auth/constants';
import { githubAccessToken, githubUserData, social } from '../services/Service'; 
import { useNavigate } from 'react-router-dom';

function GithubSignin() {
  const [message, setMessage] = useState({ text: '', color: '' });
  const navigate = useNavigate();

  const githubsubmit = () => {
    const popup = window.open(
      `https://github.com/login/oauth/authorize?client_id=${GITHUB_CLIENTID}&redirect_uri=${REDIRECT_URI}&scope=read:user`, 
      'github-login', 
      'width=600,height=600'
    );

    const interval = setInterval(() => {
      try {
        if (popup.closed) {
          clearInterval(interval);
        } else {
          const params = new URLSearchParams(popup.location.search);
          const code = params.get('code');
          if (code) {
            clearInterval(interval);
            popup.close();
            handleGithubLogin(code);
          }
        }
      } catch (error) {
        
      }
    }, 1000);
  };

  const handleGithubLogin = (code) => {
    githubAccessToken(code)
      .then((data) => {
        fetchUserData(data.access_token);
        console.log(data.access_token);
      })
      .catch((err) => {
        console.error("Error fetching the access token:", err);
        setMessage({ text: 'Error fetching the access token. Please try again later.', color: 'text-red-500' });
      });
  };

  const fetchUserData = (accessToken) => {
    githubUserData(accessToken)
      .then(async (data) => {
        try {
          const response = await social(data.login);
          localStorage.setItem('firstName', response.firstName);
          localStorage.setItem('token', response.token);
          localStorage.setItem('roles', JSON.stringify(response.roles));
          localStorage.setItem('permissions', JSON.stringify(response.permissions));

          navigate('/UserWelcome');  
        } catch (err) {
          setMessage({ text: err.response?.data?.message || 'GitHub Login failed', color: 'text-red-500' });
          console.error('Error:', err);
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setMessage({ text: 'Error fetching user data. Please try again later.', color: 'text-red-500' });
      });
  };

  return (
    <>
      {message.text && <p className={`mt-3 ${message.color}`}>{message.text}</p>}
      <div className="flex items-center justify-center w-full space-y-4">
        <button
          onClick={githubsubmit}
          className="bg-[#00AAFF] hover:bg-blue-500 text-white font-light py-3 px-4 mt-4 w-full rounded flex items-center justify-center space-x-3 shadow-md"
        >
          <FaGithub size={24} />
          <span>Signin with GitHub</span>
        </button>
      </div>
    </>
  );
}

export default GithubSignin;
