import React from 'react';

const UserWelcome = ({ userType }) => {
    const firstName = localStorage.getItem('firstName');
    const roles = JSON.parse(localStorage.getItem('roles'));

    const welcomeMessage = `Welcome ${firstName}!`;
    const rolesMessage = roles ? `Your roles: ${roles.join(', ')}` : ''; 


    return (
        <div className="flex justify-center items-center">
            <div className="flex flex-col items-center space-y-6 p-10 bg-white shadow-lg rounded-lg w-full max-w-2xl mx-auto my-5">
                <p className="text-2xl text-gray-700 font-bold text-center">{welcomeMessage}</p>
                {rolesMessage && (
                    <p className="text-lg text-gray-600 text-center font-bold">{rolesMessage}</p>
                )}
            </div>
        </div>
    );
};

export default UserWelcome;
